html,
body,
#root {
  height: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0;
  font-size: 10px;
  font-family: "Source Serif Pro", serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

* {
  box-sizing: border-box;
}
